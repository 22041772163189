ช
<template>
  <v-card
    style="height: 100%;overflow: auto"
    class="pa-2 ml-2 rounded-lg elevation-2"
    outlined
    tile
  >

    <div v-if="searchOption.length">
      <h4 class="font-weight-regular">ค้นหา</h4>
      <v-select
        class="rounded mt-3"
        :items="searchOption"
        outlined
        dense
        height="32px"
        hide-details
        item-value="value"
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="optionSearch"
      ></v-select>
      <v-text-field
        dense
        outlined
        placeholder="กรอกข้อมูลที่ต้องการค้นหา"
        class="rounded mt-3"
        hide-details
        v-model="search"
      ></v-text-field>
    </div>

    <!--    Date-->
    <div v-if="date">
      <v-divider v-if="searchOption.length" class="my-4"/>
      <h4 class="font-weight-regular mb-4">ช่วงเวลา</h4>
      <v-btn depressed @click="showDateDialog=true" block class="d-flex justify-start">
        <v-icon left>mdi-calendar</v-icon>
        {{ dateRangeText }}
      </v-btn>
    </div>

    <!--    Max Min price-->
    <div v-if="priceMinMax">

      <v-divider class="my-4"/>

      <h4 class="font-weight-regular mb-4">ช่วงราคา</h4>

      <v-text-field
        dense
        outlined
        placeholder="กรอกข้อมูลที่ต้องการค้นหา"
        class="rounded mt-3"
        hide-details
        v-model="searchParams.min_price"
        suffix="บาท"
        type="number"
      ></v-text-field>
      <v-text-field
        dense
        outlined
        placeholder="กรอกข้อมูลที่ต้องการค้นหา"
        class="rounded mt-3"
        hide-details
        v-model="searchParams.max_price"
        suffix="บาท"
        type="number"
      ></v-text-field>
    </div>

    <div v-if="vehicle">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">รถ</h4>
      <v-select
        outlined
        dense
        height="32px"
        class="rounded"
        v-model="searchParams.vehicle_id"
        :items="vehicleOptions"
        item-text="name"
        item-value="id"
        hide-details="auto"
        placeholder="เลือกดูตามรถ"
      >
        <template slot="selection" slot-scope="data">
          <div style="display: flex;width: 100%;justify-content: space-between">
            <span>{{ data.item.name }}</span>
            <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
          </div>
        </template>
        <template slot="item" slot-scope="data">
          <div style="display: flex;width: 100%;justify-content: space-between">
            <span>{{ data.item.name }}</span>
            <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
          </div>
        </template>
      </v-select>
    </div>

    <div v-if="seller">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">เซลล์</h4>
      <v-select
        clearable
        outlined
        dense
        height="32px"
        class="rounded"
        v-model="searchParams.seller_id"
        :items="sellerOptions"
        item-value="id"
        hide-details="auto"
        placeholder="ไม่ได้เลือกเซลล์"
      >
        <template slot="selection" slot-scope="data">
          {{ data.item.first_name }} {{ data.item.last_name }}
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.first_name }} {{ data.item.last_name }}
        </template>
      </v-select>
    </div>

    <div v-if="categoryName">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">ประเภทสินค้า</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="categoryOptions"
        outlined
        dense
        height="32px"
        hide-details
        item-value="name"
        item-text="name"
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.category_name"
      ></v-select>
    </div>

    <div v-if="paymentStatus">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">ชำระเงิน</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="paymentStatusOptions"
        outlined
        dense
        height="32px"
        hide-details
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.payment_status"
      ></v-select>
    </div>

    <div v-if="paymentMethod">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">วิธีชำระเงิน</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="paymentMethodOptions"
        outlined
        dense
        height="32px"
        hide-details
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.payment_method"
      ></v-select>
    </div>

    <div v-if="orderStatus">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">สถานะออเดอร์</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="orderStatusOptions"
        outlined
        dense
        height="32px"
        hide-details
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.order_status"
      ></v-select>
    </div>

    <div v-if="oilStatus">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">น้ำมัน</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="oilStatusOption"
        outlined
        dense
        height="32px"
        hide-details
        item-text="text"
        item-value="value"
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.oil_status"
      ></v-select>
    </div>

    <div v-if="poStatus">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">สถานะ PO</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="poStatusOption"
        outlined
        dense
        height="32px"
        hide-details
        item-text="name"
        item-value="id"
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.po_status"
      ></v-select>
    </div>

    <div v-if="poSupplier">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">สถานที่รับของ (supplier)</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="poSupplierOptions"
        outlined
        item-text="name"
        item-value="id"
        dense
        height="32px"
        hide-details
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.po_supplier"
      ></v-select>
    </div>

    <div v-if="deliveryType">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">ประเภทการส่ง</h4>
      <v-select
        clearable
        class="rounded mt-3"
        :items="deliveryOptions"
        outlined
        item-text="name"
        item-value="id"
        dense
        height="32px"
        hide-details
        placeholder="เลือกข้อมูลที่ต้องการค้นหา"
        v-model="searchParams.delivery_type"
      ></v-select>
    </div>

    <div v-if="taxOrder">
      <v-divider class="my-4"/>
      <h4 class="font-weight-regular mb-4">การออกใบกำกับภาษี</h4>
      <v-select
        v-model="searchParams.is_tax_invoice"
        :items="texOrderOptions"
        item-text="text"
        item-value="value"
        clearable
        class="rounded mt-3"
        outlined
        dense
        placeholder="ทั้งหมด"
      ></v-select>
    </div>


    <v-row class="mx-0 px-0 mb-6 mt-0">
      <v-btn class="mt-6 mr-2" color="primary" @click="goSearch()" depressed>
        <v-icon left>mdi-magnify</v-icon>
        ค้นหา
      </v-btn>
      <vue-json-to-csv
        v-if="csv"
        :json-data="jsonData"
        :labels="labels"
        :csv-title="dateNameFile+'_'+fileName"
      >
        <v-btn class="mt-6" color="green" dark depressed>
          <v-icon left>mdi-table-large</v-icon>
          รายงาน (csv)
        </v-btn>
      </vue-json-to-csv>
      <!--      <v-btn v-if="csv" class="mt-6" color="green" dark @click="goSearch(true)" depressed>-->
      <!--        <v-icon left>mdi-table-large</v-icon>-->
      <!--        รายงาน (csv)-->
      <!--      </v-btn>-->
      <slot name="csv"></slot>
    </v-row>

    <v-dialog
      ref="dialog"
      v-model="showDateDialog"
      :return-value.sync="dates"
      persistent
      width="290px"
    >
      <v-date-picker
        v-model="dates"
        range
      >
        <v-spacer></v-spacer>

        <v-btn
          text
          color="primary"
          @click="showDateDialog = false;"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="dates.length === 0"
          text
          color="primary"
          @click="$refs.dialog.save(dates);"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>

  </v-card>
</template>

<script>
import axios from 'axios'
import DateTime from "luxon/src/datetime";
import VueJsonToCsv from 'vue-json-to-csv'
// import DatePicker from "@/components/DatePicker";

export default {
  props: {
    searchOption: {
      type: Array,
      default: () => ([])
    },
    urlForDate: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    priceMinMax: {
      type: Boolean,
    },
    date: {
      type: Boolean
    },
    vehicle: {
      type: Boolean
    },
    seller: {
      type: Boolean
    },
    paymentStatus: {
      type: Boolean
    },
    paymentMethod: {
      type: Boolean
    },
    orderStatus: {
      type: Boolean
    },
    oilStatus: {
      type: Boolean
    },
    poStatus: {
      type: Boolean
    },
    poSupplier: {
      type: Boolean,
    },
    categoryName: {
      type: Boolean
    },
    csv: {
      type: Boolean
    },
    jsonData: {
      type: Array
    },
    labels: {
      type: Object
    },
    fileName: {
      type: String
    },
    backwards: {
      type: Number,
      default: 0
    },
    taxOrder: {
      type: Boolean,
    },
    fixFilter: {
      type: Object,
      default: () => ({})
    },
    deliveryType: {
      type: Boolean
    }
  },
  components: { VueJsonToCsv },
  name: "FilterCard",
  data: () => ({
    search: '',
    optionSearch: null,
    dates: [],
    showDateDialog: false,
    vehicleOptions: [],
    sellerOptions: [],
    categoryOptions: [],
    poSupplierOptions: [],
    texOrderOptions: [{ text: 'เฉพาะออกใบกำกับภาษี', value: true }, { text: 'ไม่ออกใบกำกับภาษี', value: false }],
    paymentStatusOptions: ['ยังไม่ชำระ', 'ชำระแล้ว'],
    deliveryOptions: ['ออกไปส่ง', 'จ้างรถข้างนอก', 'ลูกค้ามารับ'],
    paymentMethodOptions: ['เงินสด', 'โอนเงิน', 'บัตรเครดิต', 'ชำระภายหลัง'],
    orderStatusOptions: ['ใบเสนอราคา', 'รับออเดอร์', 'ดำเนินการ', 'สำเร็จ'],
    poStatusOption: ['รอรับสินค้า', 'รับสินค้าแล้ว'],
    oilStatusOption: [{ text: 'เติมเงิน', value: 'IN' }, { text: 'เติมน้ำมัน', value: 'OUT' }],
    searchParams: {
      start_date: null, //
      end_date: null, //
      min_price: 0, //
      max_price: 1000000,//
      vehicle_id: null,//
      seller_id: null,//
      payment_status: null,//
      payment_method: null,//
      order_status: null, //
      oil_status: null,
      po_status: null,
      po_supplier: null,
      category_name: null, //
      is_tax_invoice: null,
      delivery_type: null
    },
    queryString: ''
  }),
  async created() {
    if (this.backwards === 0) {
      this.dates = [DateTime.now().toFormat('yyyy-LL-dd')]
    } else if (this.backwards < 1) {
      this.dates = [DateTime.now().minus({ days: this.backwards * 10 }).toFormat('yyyy-LL-dd'), DateTime.now().toFormat('yyyy-LL-dd')]
    } else {
      this.dates = [DateTime.now().minus({ months: this.backwards }).toFormat('yyyy-LL-dd'), DateTime.now().toFormat('yyyy-LL-dd')]
    }

    this.goSearch()
    // if (this.vehicle) {
    //   this.getVehicle()
    // }
    // if()
    try {
      this.vehicleOptions = this.vehicle ? (await axios.get('/api/stocks/vehicle/')).data.data : []
      this.sellerOptions = this.seller ? (await axios.get('/api/stocks/seller/')).data.data : []
      this.categoryOptions = this.categoryName ? (await axios.get('/api/stocks/category/')).data.data : []
      this.poSupplierOptions = this.poSupplier ? (await axios.get('/api/stocks/supplier/')).data.data : []
      // console.log(this.sellerOptions)
      // this.getSeller()
    } catch (e) {
      console.log(e)
    }
    // console.log(this.makeQueryString())
    this.$emit('search', this.makeQueryString())
  },
  methods: {
    goSearch() {
      let dates = this.dates.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('yyyy/LL/dd')).sort()
      if (dates.length === 1) {
        this.searchParams.start_date = dates[0]
        this.searchParams.end_date = dates[0]
      } else if (dates.length === 2) {
        this.searchParams.start_date = dates[0]
        this.searchParams.end_date = dates[1]
      }
      if (this.optionSearch) {
        this.searchParams[this.optionSearch] = this.search
      }
      if (!this.priceMinMax) {
        delete this.searchParams.min_price
        delete this.searchParams.max_price
      }
      // console.log(DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')))

      for (const [key, value] of Object.entries(this.fixFilter)) {
        this.searchParams[key] = value
      }
      // if(isCsv){
      this.$emit('search', this.makeQueryString())
      this.$emit('search-object', this.searchParams)
      // }

    },
    makeQueryString() {
      let str = [];
      for (const [key, value] of Object.entries(this.searchParams)) {
        if (value !== null) {
          str.push(key + "=" + value);
        }
        // if (index === 0) {
        //   this.queryString += `?${key}=${value}`
        // } else {
        //   this.queryString += `&${key}=${value}`
        // }

      }
      return '?' + str.join("&")
    }
  },
  computed: {
    dateRangeText() {
      if (this.dates === null) {
        return ""
      } else {
        // this.dates.sort()
        return this.dates.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')).sort().join(' - ')
      }
    },
    dateNameFile() {
      let dates = this.dates.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')).sort()
      if (!this.date) {
        return ''
      } else if (dates.length === 1) {
        return `${dates[0]}`
      } else if (dates.length === 2) {
        return `${dates[0]}-${dates[1]}`
      } else {
        return ''
      }
    }
  },
  watch: {
    optionSearch(value, oldValue) {
      delete this.searchParams[oldValue]
    }
  }
}
</script>

<style scoped>

</style>
