<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ประวัติการจ่ายค่าคอมมิชชั่น
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="showFilter = !showFilter" icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="mx-2 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" style="overflow: auto" class="pl-2 pb-4">
          <v-card
              class="rounded-lg elevation-2 mr-2"
              outlined
              tile
          >

            <v-data-table
                :search="search"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="commissionHistory"
                :header-props="{ sortIcon: null }"
                :single-expand="true"
                show-expand
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-0">
                  <v-data-table :headers="commissionHeaders"
                                :items="item.commissions"
                                hide-default-footer
                                style="width: 100%"
                                :items-per-page="-1">
                    <template v-slot:item="{ item }">
                      <tr class="blue lighten-5">
                        <td>{{ item.date }}</td>
                        <td>{{ showName(item) }}</td>
                        <td>{{ item.price }}</td>
                        <td>
                          <router-link :to="goRef(item).link">{{ goRef(item).text }}</router-link>
                        </td>
                      </tr>
                    </template>

                    <template slot="body.append">
                      <tr class="blue lighten-5">
                        <td>
                          ราคาสุทธิ
                        </td>
                        <td></td>
                        <td>{{ sumEachTransaction(item.commissions) }}</td>
                        <td></td>
                      </tr>
                    </template>
                  </v-data-table>
                </td>
              </template>

              <template v-slot:item.user="{item}">
                {{ item.user.first_name }} {{ item.user.last_name }}
              </template>
            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <!--          <v-card-->
          <!--              class="pa-2 rounded-lg elevation-2"-->
          <!--              outlined-->
          <!--              tile-->
          <!--          >-->
          <!--            <vue-json-to-csv-->
          <!--                :json-data="commissionHistory"-->
          <!--            >-->
          <!--              &lt;!&ndash;              <button class="teal button__custom">&ndash;&gt;-->
          <!--              &lt;!&ndash;                <b>Download with only selected labels</b>&ndash;&gt;-->
          <!--              &lt;!&ndash;              </button>&ndash;&gt;-->
          <!--            </vue-json-to-csv>-->
          <!--            &lt;!&ndash;            <h4 class="font-weight-regular">ค้นหา</h4>&ndash;&gt;-->
          <!--            &lt;!&ndash;            <v-select&ndash;&gt;-->
          <!--            &lt;!&ndash;                class="rounded mt-3"&ndash;&gt;-->
          <!--            &lt;!&ndash;                :items="header"&ndash;&gt;-->
          <!--            &lt;!&ndash;                outlined&ndash;&gt;-->
          <!--            &lt;!&ndash;                dense&ndash;&gt;-->
          <!--            &lt;!&ndash;                height="32px"&ndash;&gt;-->
          <!--            &lt;!&ndash;                hide-details&ndash;&gt;-->
          <!--            &lt;!&ndash;                placeholder="เลือกข้อมูลที่ต้องการค้นหา"&ndash;&gt;-->
          <!--            &lt;!&ndash;                v-model="optionSearch"&ndash;&gt;-->
          <!--            &lt;!&ndash;            ></v-select>&ndash;&gt;-->
          <!--            &lt;!&ndash;            <v-text-field&ndash;&gt;-->
          <!--            &lt;!&ndash;                dense&ndash;&gt;-->
          <!--            &lt;!&ndash;                outlined&ndash;&gt;-->
          <!--            &lt;!&ndash;                placeholder="กรอกข้อมูลที่ต้องการค้นหา"&ndash;&gt;-->
          <!--            &lt;!&ndash;                class="rounded mt-3"&ndash;&gt;-->
          <!--            &lt;!&ndash;                hide-details&ndash;&gt;-->
          <!--            &lt;!&ndash;                v-model="search"&ndash;&gt;-->
          <!--            &lt;!&ndash;            ></v-text-field>&ndash;&gt;-->
          <!--            <v-divider class="my-4"/>-->
          <!--          </v-card>-->
          <FilterCard @search="getCommissionHistory"
                      date
                      :search-option="[]"
          />
        </pane>
      </splitpanes>
      <!--      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">-->
      <!--        <v-col-->
      <!--            style="height: 100%"-->
      <!--        >-->
      <!--          <v-data-table-->
      <!--              :search="search"-->
      <!--              loading-text="Loading... Please wait"-->
      <!--              :headers="header"-->
      <!--              :items="customers"-->
      <!--              :header-props="{ sortIcon: null }"-->
      <!--              @click:row="goCustomerDetail"-->
      <!--          >-->

      <!--          </v-data-table>-->
      <!--        </v-col>-->
      <!--        <v-expand-x-transition>-->

      <!--          <v-card-->
      <!--              v-show="showFilter"-->
      <!--              class="pa-2 rounded-lg elevation-2 ma-1"-->
      <!--              outlined-->
      <!--              tile-->
      <!--          >-->
      <!--            <h4 class="font-weight-regular">ค้นหา</h4>-->
      <!--            <v-select-->
      <!--                class="rounded mt-3"-->
      <!--                :items="header"-->
      <!--                outlined-->
      <!--                dense-->
      <!--                height="32px"-->
      <!--                hide-details-->
      <!--                placeholder="เลือกข้อมูลที่ต้องการค้นหา"-->
      <!--                v-model="optionSearch"-->
      <!--            ></v-select>-->
      <!--            <v-text-field-->
      <!--                dense-->
      <!--                outlined-->
      <!--                placeholder="กรอกข้อมูลที่ต้องการค้นหา"-->
      <!--                class="rounded mt-3"-->
      <!--                hide-details-->
      <!--                v-model="search"-->
      <!--            ></v-text-field>-->
      <!--            <v-divider class="my-4"/>-->
      <!--          </v-card>-->
      <!--        </v-expand-x-transition>-->
      <!--      </v-row>-->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import VueJsonToCsv from 'vue-json-to-csv'
import FilterCard from "@/components/FilterCard";

export default {
  name: "salaryHistory",
  components: {FilterCard, Splitpanes, Pane},
  data: () => ({
    showFilter: true,
    search: '',
    commissionHistory: [],
    headers: [
      {
        text: 'วันที่',
        value: 'date'
      },
      {
        text: 'ชื่อ',
        value: 'user'
      },
      {
        text: 'เพิ่ม',
        value: 'add'
      },
      {
        text: 'หัก',
        value: 'minus'
      },
      {
        text: 'ยอดที่จ่าย',
        value: 'total_price'
      },
      {
        text: 'หมายเหตุ',
        value: 'remark'
      },
      {
        text: '',
        value: 'data-table-expand'
      },
    ],
    commissionHeaders: [
      {
        text: 'วันที่',
        value: 'date',
        class: 'blue lighten-4'
      },
      {
        text: 'รายการ',
        value: 'name',
        class: 'blue lighten-4'
      },
      {
        text: 'ค่าคอมมิชชั่น',
        value: 'price',
        class: 'blue lighten-4'
      },
      {
        text: 'อ้างอิง',
        value: 'ref',
        class: 'blue lighten-4'
      }
    ],

  }),
  created() {
    // this.getCommissionHistory()
  },
  methods: {
    getCommissionHistory(params) {
      axios.get(`/api/tracks/commission-group/${this.$route.params.id}/${params}`)
          .then(res => {
            console.log(res.data.data)
            this.commissionHistory = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    showName(item) {
      if (item.po !== null) {
        return "รับสินค้า"
      } else if (item.order !== null) {
        return "ออเดอร์"
      }
    },
    goRef(item) {
      if (item.order !== null) {
        console.log(item)
        return {
          link: '/order/' + item.order,
          text: 'ดูออเดอร์ ' + String(item.order).padStart(4, '0')
        }
      } else if (item.po_id !== null) {
        return {
          link: '/stock/po/' + item.po,
          text: 'ดู PO ' + item.po
        }
      }
    },
    sumEachTransaction(commission) {
      return commission.reduce((accum, item) => accum + item.price, 0)
    }
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  background-color: #e8e8e8 !important
}
</style>